<template>
  <div class="container bg">
    <div class="twoMenubox">
      <div
        v-for="(i, idx) in menu"
        :key="idx"
        class="item"
        :class="current == idx ? 'active' : ''"
        @click="menuHandle(i, idx)"
      >
        <img
          style="width: 1.5rem;height: 1.65rem;"
          :src="current == idx ? i.icon : i.activeicon"
          alt=""
        />
        <div style="text-align: left">{{ i.title }}</div>
      </div>
    </div>
    <div class="right_info">
      <div v-if="current == 0">
        <div
          class="info_top"
          :title="info.name"
        >
          <img
            v-show="this.info.face"
            :src="this.info.face"
          >
          <img
            v-show="!this.info.face"
            src="~@/assets/img/noface.png"
          >
          <div class="info_userName">{{ this.info.name }}</div>
          <span
            class="edit_pen"
            @click="edit()"
          ><i class="el-icon-edit"></i></span>
          <div class="status">
            <i class="el-icon-s-custom"></i> 高级用户
          </div>
        </div>
        <div class="info_center">
          <div
            class="div_info"
            style="left: -50px;"
          >
            <div class="div_info_title">软件到期时间</div>
            <div
              style="opacity: 1; font-size: 20px;font-weight: 400;letter-spacing: 0px;line-height: 28.96px;color: rgba(101, 230, 152, 1);text-align: left;vertical-align: top;position: relative; top: 80px; left: 80px;"
            >
              {{ info.use_time }}
            </div>
          </div>
          <div class="div_info">
            <div class="div_info_title">视频合成时长（秒）</div>
            <div class="div_info_desc">
              {{ info.come_time }}
            </div>
          </div>
          <div
            class="div_info"
            style="left: 50px"
          >
            <div class="div_info_title">AI绘画次数</div>
            <div class="div_info_desc">
              {{ info.draw_number }}
            </div>
          </div>
        </div>
        <div class="info_botom">
          <div
            class="div_info"
            style="left: -50px"
          >
            <div class="div_info_title">
              形象克隆次数
            </div>
            <div class="div_info_desc">
              {{ info.image_copy }}
            </div>
          </div>
          <div class="div_info">
            <div class="div_info_title">
              高阶形象克隆次数
            </div>
            <div class="div_info_desc">
              {{ info.high_image_copy }}
            </div>
          </div>
          <div
            class="div_info"
            style="left: 50px"
          >
            <div class="div_info_title">
              声音克隆次数
            </div>
            <div class="div_info_desc">
              {{ info.sound_copy }}
            </div>
          </div>
        </div>
        <div class="info_tips"></div>
        <img
          src="~@/assets/img/icon_tips.png"
          style="width: 10px; height: 14px; position:relative;margin-left: -150px;margin-top: 120px"
        >
        <div class="info_tips_text">时长不足请联系客服增加</div>
      </div>
      <div v-if="current == 1">
        <div class="edit_out"></div>
        <div class="edit_content">
          <el-upload
            class="avatar-uploader"
            :action="actions"
            :data="params"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :on-error="handleAvatarError"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="imageUrl"
              :src="imageUrl"
              class="avatar"
            />
            <img
              v-if="imageUrl"
              src="~@/assets/img/secondMenu/camera.png"
              class="avatar_top"
            >
          </el-upload>
          <div class="edit_username">
            用户昵称
          </div>
          <el-input
            v-model="form.name"
            style="width: 300px"
          ></el-input>
          <div class="edit_subit">
            <div class="edit_subit_left">
              <el-button
                style="margin-right: 20px"
                @click="$router.go(-1)"
              >返回</el-button>
            </div>
            <div class="edit_subit_right">
              <el-button
                type="primary"
                @click="submit"
              >提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUser, set_user } from "@/api/user";

export default {
  name: "Setting",
  data () {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      params: {},
      actions: "api/users/set_user",
      current: 0,
      imgId: "",
      name: "测试用户123",
      craft: "3680",
      time: "2023/9/30",
      url: "https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png",
      info: {},
      imageUrl: "",
      menu: [
        {
          icon: require("@/assets/img/secondMenu/peopleCenter1.png"),
          activeicon: require("@/assets/img/secondMenu/peopleCenter.png"),
          title: "个人中心",
          pageName: "peopleCenter",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/peopleCenter1.png"),
          activeicon: require("@/assets/img/secondMenu/editInfo.png"),
          title: "修改信息",
          pageName: "editInfo",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/logOut.png"),
          activeicon: require("@/assets/img/secondMenu/logOut.png"),
          title: "退出登陆",
          pageName: "logOut",
          params: {},
        },
      ],
    };
  },
  mounted () {
    // 渲染视频
    this.actions = process.env.VUE_APP_API_BASE_URL + "api/users/set_user";
    this.params.token = localStorage.getItem("token");
    // 渲染视频
    const { name, face } = JSON.parse(localStorage.getItem("userInfo"));
    this.form.name = name || "";
    this.imageUrl = face || "";
    this.info = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.info);
    this.getInfo();
    this.userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {};
    if (this.$route.params.imageId && this.$route.params.imageId > 0) {
      this.current = 1;
      this.imgId = this.$route.params.imageId;
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      console.log(file);
      this.$message.success("上传头像成功!");
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleAvatarError (res, file) {
      console.log(res);
    },
    edit () {
      if (this.current == 0) {
        this.current = 1
      } else {
        this.current = 0
      }
    },
    submit () {
      set_user({ name: this.form.name }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.$message.success("修改成功!");
          getUser({ token: res.data.token }).then((res1) => {
            if (res1.code == "200") {
              localStorage.setItem("userInfo", JSON.stringify(res1.data));
              setTimeout(() => {
                this.$router.go(-1);
              }, 500);
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    beforeAvatarUpload (file) {
      const isType = file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isType) {
        this.$message.error("上传头像图片只能是 JPG 或 PNG 格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传头像图片大小不能超过 5MB!");
      }
      return isType && isLt5M;
    },
    onPaste (event) {
      // 处理粘贴事件
      console.log("onPaste", event.clipboardData.getData("text/plain"));
    },
    menuHandle (e, idx) {
      this.current = e ? idx : 0;
      if (this.current == 2) {
        this.logout()
      }
      const linkInfo = this.menu[this.current];
      console.log("[home] load page:", linkInfo);
    },
    logout () {
      console.log(this.$store.state.mInterval);
      clearInterval(this.$store.state.mInterval);
      localStorage.clear();
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style lang="less" scoped>
.twoMenubox {
  position: relative;
  width: 210px;
  height: 57rem;
  background: #080114;
  // border-right: 1px solid #eef0f2;
  .item {
    font-size: 16px !important;
  }
}

.box {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  background: #fff;
  margin: 20px 0;
  margin-bottom: 0;
  height: 80vh;
  border-radius: 9px;
  overflow: hidden;

  .top {
    width: 100%;
    height: 230px;
    background: url(../../assets/img/myBg.png) no-repeat;
    background-size: 100% 100%;
    padding: 60px 30px;

    .logo {
      background: #fff;
      border-radius: 100px;
      width: 106px;
      height: 106px;
      display: flex;
      align-items: center;

      img {
        width: 105px;
        height: 105px;
        border-radius: 50px;
      }
      .title {
        font-weight: bold;
        font-size: 18px;
        margin-left: 25px;

        .name {
          width: 230px;
          text-indent: -12px;
          justify-content: space-around;
          align-items: baseline;
        }
      }
    }
  }

  .info {
    text-align: left;
    margin: 40px 20px 25px;
    font-size: 15px;
    background: #f5f9ff;
    border-radius: 10px;
    padding: 30px 20px;

    .timeBox {
      height: 50px;
      line-height: 50px;

      .icon1 {
        font-size: 18px;
        color: #006dfe;
        margin-right: 12px;
      }
    }
  }
  .tips {
    color: #808080;
    font-size: 14px;
  }

  .left {
    text-align: left;
    margin-left: 30px;
  }
}

.right_info {
  position: relative;
  top: -877px;
  left: 480px;
  width: 970px;
  height: 722px;
  /*background: green;*/
  .info_top {
    position: relative;
    left: 361px;
    top: 0px;
    width: 249px;
    height: 100px;
    opacity: 1;
    /*background: #000c17;*/
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;
      float: left;
    }
    .info_userName {
      position: relative;
      left: 23px;
      top: 23px;
      width: 90px;
      height: 24px;
      opacity: 1;
      display: flex;
      overflow: hidden;

      /** 文本1 */
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 24px;
      color: rgba(255, 255, 255, 1);
      text-align: left;
      vertical-align: top;
    }
    .edit_pen {
      display: inline-block;
      position: relative;
      color: white;
      left: 6.275rem;
      top: -3px;
      width: 13.09px;
      height: 13.85px;
      opacity: 1;
    }
    .status {
      color: white;
      padding: 3px;
      display: inline-block;
      position: relative;
      left: -5px;
      top: 30px;
      width: 100px;
      height: 30px;
      opacity: 1;
      border-radius: 3px;
      background: rgba(232, 70, 70, 1);
    }
  }
  .info_center {
    position: relative;
    left: 0px;
    top: 60px;
    width: 970px;
    height: 200px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background: darkslategray;*/
  }
  .info_botom {
    position: relative;
    left: 0px;
    top: 120px;
    width: 970px;
    height: 200px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background: darkslategray;*/
  }
  .div_info {
    display: inline-block;
    position: relative;
    width: 290px;
    height: 200px;
    opacity: 0.8;
    border-radius: 22px;
    border: 2px solid rgba(149, 158, 196, 1);
    .div_info_title {
      position: relative;
      top: 56px;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 26.06px;
      color: rgba(149, 158, 196, 1);
      text-align: center;
      vertical-align: top;
    }
    .div_info_desc {
      position: relative;
      top: 60px;
      left: -5px;
      opacity: 1;
      /** 文本1 */
      font-size: 38.55px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 55.82px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      vertical-align: top;
    }
  }
  .info_tips {
    position: relative;
    left: 235px;
    top: 150px;
    width: 500px;
    height: 47px;
    opacity: 0.1;
    border-radius: 10px;
    background: rgba(204, 204, 204, 1);
  }
  .info_tips_text {
    position: relative;
    left: 430px;
    top: -15px;
    width: 132px;
    height: 18px;
    opacity: 1;
    /** 文本1 */
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 17.38px;
    color: rgba(255, 255, 255, 0.7);
    text-align: left;
    vertical-align: top;
  }
}

.edit_out {
  position: relative;
  left: 0px;
  top: 50px;
  width: 970px;
  height: 600px;
  opacity: 0.2;
  border-radius: 22px;
  border: 2px solid rgba(149, 158, 196, 1);
}
.avatar {
  left: 0px;
  top: 0px;
  width: 150px;
  height: 150px;
  opacity: 0.5;
  background: rgba(0, 0, 0, 1);
  border-radius: 50%;
}
.avatar_top {
  position: relative;
  left: -100px;
  top: -5px;
  width: 50px;
  height: 46.25px;
  opacity: 1;
}
.edit_content {
  position: relative;
  left: 242px;
  top: -474px;
  width: 486px;
  height: 250px;
  opacity: 1;
  /*background: #00baad;*/
  /deep/.el-input__inner {
    position: relative;
    left: -30px;
    top: 35px;
    width: 400px;
    height: 50px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(15, 19, 25, 1);
    border: 1px solid rgba(45, 50, 64, 1);
  }
}
.edit_username {
  position: relative;
  left: -1.25rem;
  top: 4.5rem;
  width: 56px;
  height: 21px;
  opacity: 0.8;
  /** 文本1 */
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
}
.edit_subit {
  position: relative;
  top: 100px;
}
.edit_subit_left {
  float: left;
  margin-left: 80px;
  ::v-deep {
    .el-button {
      width: 132px;
      height: 48px;
      opacity: 1;
      border-radius: 10px;
      border: 2px solid rgba(13, 225, 179, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 50px 12px 50px;
      background: #0000;

      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: rgba(13, 225, 179, 1);
    }
  }
}
.edit_subit_right {
  float: left;
  ::v-deep {
    .el-button {
      left: 174px;
      top: 0px;
      width: 132px;
      height: 48px;
      opacity: 1;
      border-radius: 10px;
      background: rgba(13, 225, 179, 1) !important;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 50px 12px 50px;
      border: none !important;

      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 24px;
      color: rgba(32, 37, 50, 1);
    }
  }
}
::v-deep {
  p {
    max-width: 100%;
    text-align: left;
    text-indent: 24px;
    font-size: 16px;
  }

  .text {
    padding: 30px;
    padding-top: 0px;
  }
}
</style>
